"use client";

import { useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "sonner";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";

export function WhatsAppNotificationForm() {
  const [value, setValue] = useState("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const t = useTranslations("footer");
  const { locale } = useParams();

  // 2. Define a submit handler.
  async function submitHandler(e: any) {
    e.preventDefault();
    setIsSending(true);
    try {
      const response = await fetch("/api/v1/subscribe/notification", {
        method: "POST",
        body: JSON.stringify({ mobileNumber: value }),
      });

      await response.json();

      if (response.status > 299) {
        toast.error(
          locale === "en"
            ? "Error while subscribing!"
            : "حدث خطأ أثناء الاشتراك!"
        );
        return;
      }

      toast.success(
        locale === "en"
          ? "You have subscribed successfully!"
          : "تم الاشتراك بنجاح!"
      );
    } catch (error: any) {
      toast.error(
        locale === "en" ? "Error while subscribing!" : "حدث خطأ أثناء الاشتراك!"
      );
    } finally {
      setIsSending(false);
    }
  }

  return (
    <div>
      <form onSubmit={submitHandler}>
        <PhoneInputWithCountrySelect
          international
          countryCallingCodeEditable={false}
          defaultCountry="SA"
          value={value}
          onChange={setValue}
          className="phoneInputWIthCountry border rounded shadow p-2"
          disabled={isSending}
        />
        <button
          type="submit"
          disabled={isSending}
          className="bg-gray-300 text-gray-900 py-2 px-4 text-sm font-medium mt-4 rounded shadow-sm flex items-center gap-2"
        >
          {isSending && (
            <AiOutlineLoading3Quarters className="w-4 h-4 animate-spin" />
          )}
          {isSending ? t("loading_label") : t("sub_button")}
        </button>
      </form>
    </div>
  );
}

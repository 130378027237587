"use client";
import Image from "next/image";
import Link from "next/link";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { SiteDetail } from "../../interfaces/site-model";
import styles from "./BottomFixedMenus.module.scss";
import { useTranslations } from "next-intl";

interface FooterProps {
  data: SiteDetail;
}

const BottomFixedMenus = ({ data }: FooterProps) => {
  const t = useTranslations("landing_hero");
  // Construct the WhatsApp link
  function buildWhatsAppMessageLink() {
    let whatsappLink: any = `https://wa.me/${data?.contact_details?.whatsapp_number}`;

    // Open the WhatsApp link in a new tab/window
    window.open(whatsappLink, "_blank");
  }

  return (
    <div className="fixed bottom-4 right-4 z-50 flex flex-col gap-4">
      <Link
        href={`tel:+966920003455`}
        target="_blank"
        className="bg-white px-4 py-2 flex items-center justify-center gap-3 rounded-lg self-end"
      >
        <FaPhone className="text-[#5a558e] w-5 h-5" />
        <span className="font-medium">{t("call")}</span>
      </Link>
      <Link
        href={`https://wa.me/+966920003455`}
        target="_blank"
        className="bg-[#25D366] text-white px-4 py-2 flex items-center justify-center gap-3 rounded-lg self-end"
      >
        <FaWhatsapp className="text-white w-5 h-5" />
        <span className="font-medium">{t("whatsapp")}</span>
      </Link>
    </div>
  );
};

export default BottomFixedMenus;

import React from "react";
import { cn } from "../lib/utils";

interface IContainer {
  className?: string;
  children: React.ReactNode;
}

export const Container: React.FC<IContainer> = ({ className, children }) => {
  return (
    <div className={cn("w-full lg:max-w-[80%] mx-auto px-5", className)}>
      {children}
    </div>
  );
};

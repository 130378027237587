"use client";

import { useTranslations } from "next-intl";
import Image from "next/image";
import {
  FaEnvelope,
  FaInstagram,
  FaPhone,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { SiteDetail } from "../../interfaces/site-model";
import { Link } from "../../i18n/routing";
import { Container } from "../container";
import { PaymentOptions } from "../../utils/payment-options";
import { WhatsAppNotificationForm } from "../whatsapp-notification-form";
import { useParams } from "next/navigation";

const siteMenu = [
  {
    path: "/",
    label: "home",
  },
  {
    path: "/about-us",
    label: "about us",
  },
  // {
  //   path: "/our-services",
  //   label: "our services",
  // },
  {
    path: "/our-offers",
    label: "our offers",
  },
  {
    path: "/our-blogs",
    label: "our blogs",
  },
  {
    path: "/terms-and-conditions",
    label: "tc",
  },
  {
    path: "/faq",
    label: "faq",
  },
  {
    path: "/contact-us",
    label: "contact us",
  },
];

interface FooterProps {
  data: SiteDetail;
  services: any;
}

const Footer = ({ data, services }: FooterProps) => {
  const t = useTranslations("footer");
  const { locale } = useParams();

  let address = `${data?.address?.address_line}, ${data?.address?.city}, ${data?.address?.country}`;

  const mainServices = services
    .map((service) => service)
    .sort((a: any, b: any) => a?.attributes?.order - b?.attributes?.order);

  return (
    <footer className="bg-gray-900 pt-12 pb-4">
      <Container className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        <div>
          <div className="relative w-[120px] h-[80px]">
            <Image
              src={data?.site_logo?.data?.attributes?.url}
              alt={data?.site_logo?.data?.attributes?.alternativeText || "logo"}
              fill
            />
          </div>
          <p className="text-white text-sm mt-8 leading-normal">{t("about")}</p>
          <div className="mt-6">
            <div className="flex flex-col items-start gap-4">
              <Link
                href={`mailto:${data?.contact_details?.email}`}
                className="flex flex-row-reverse items-center gap-2 text-white"
              >
                {/* info@privatefleetservices.com */}
                <span>{data?.contact_details?.email}</span>
                <span>
                  <FaEnvelope />
                </span>
              </Link>
              <Link
                href={`tel:${data?.contact_details?.phone_number}`}
                className="flex flex-row-reverse items-center gap-2 text-white"
              >
                {/* +966920003455 */}
                {data?.contact_details?.phone_number}
                <span>
                  <FaPhone />
                </span>
              </Link>
              <p className="flex flex-row-reverse items-center gap-2 text-white">
                {/* {t("address")} */}
                {address}
                <span>
                  <HiOutlineLocationMarker />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <h3 className="text-xl font-medium mb-8 text-white">
            {" "}
            {t("sitemap_title")}
          </h3>
          <nav className="flex flex-col gap-4">
            {siteMenu.map((menu, index) => {
              return (
                <Link
                  href={menu.path}
                  key={index}
                  className="text-white text-sm font-medium capitalize"
                >
                  {t(menu.label)}
                </Link>
              );
            })}
          </nav>
        </div>
        <div className="mt-12">
          <h3 className="text-xl font-medium mb-8 text-white">
            {locale === "en" ? "Our Services" : "الخدمات"}
          </h3>
          <nav className="flex flex-col gap-4">
            {mainServices.map((service, index) => {
              return (
                <Link
                  href={`/our-services/${service?.attributes?.slug}`}
                  key={index}
                  className="text-white text-sm font-medium capitalize"
                >
                  {service?.attributes?.title}
                </Link>
              );
            })}
          </nav>
        </div>
        <div className="mt-12">
          <h3 className="text-xl font-medium mb-8 text-white">
            {t("sub_title")}
          </h3>
          <WhatsAppNotificationForm />
          <p className="text-white text-lg font-medium mt-4 mb-2">
            {t("pay_method_title")}
          </p>
          <div className="flex items-center gap-2">
            {PaymentOptions.map((image, index: number) => (
              <Image
                key={index}
                src={image}
                alt="payment option image"
                width={40}
                height={20}
              />
            ))}
          </div>
        </div>
      </Container>
      <hr className="mt-8 mb-4" />
      <Container className="flex items-center justify-between gap-4">
        <p className="text-white text-sm">
          {/* {t("copyright")} */}
          {data?.copyright_note}
        </p>
        <div className="flex items-center gap-4 text-white">
          <Link
            href={`tel:${data?.contact_details?.phone_number}`}
            target="_blank"
          >
            <FaPhone />
          </Link>
          <Link
            href={`https://wa.me/${data?.contact_details?.whatsapp_number}`}
            target="_blank"
          >
            <FaWhatsapp />
          </Link>
          <Link href={data?.social_links?.instagram_link || ""} target="_blank">
            <FaInstagram />
          </Link>
          <Link href={data?.social_links?.twitter_link || ""} target="_blank">
            <FaTwitter />
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
